#schedule-container .ant-list{
    border-radius: 20px;
    background-color: #232323;
    border: 1px solid #4d4d4d;
}

/*Schedule Styling*/
.schedule-list.ant-list .ant-list-header{
    padding: 0 !important;
}

.schedule-list.ant-list{
    padding: 16px 28px;
}
.schedule-list .ant-list-item{
    color:white;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 18px;
    width: 100%;
    gap: 16px;
    padding: 20px 0px;
}
.schedule-list .ant-list-item:not(:last-child){
    border-bottom: 1px solid rgba(255,255,255,0.2);
}
.schedule-list .ant-list-item p{
    margin: 0;
}

.schedule-list .schedule-date{
    font-size: 20px;
    color: white;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0px;
    border-bottom: 1px solid rgba(255,255,255,0.2);
    padding-bottom: 16px;
    text-align: center;
}

.schedule-list .schedule-court-time{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
}
.schedule-list .schedule-time{
    padding: 6px 12px;
    background-color: #A2EF56;
    color: #232323;
}
.schedule-list .schedule-court{
    color: white;
    margin-left: 12px;
    text-align: center;
}

.schedule-match-info{
    display: flex;
    align-items: center;
    justify-content: center;
    flex:1;
    width: 100%;
    padding: 6px 0px;
    margin-right: 180px;
}

.schedule-match-info .team-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex:1;
}

.schedule-match-info .vs-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    text-transform: uppercase;
    line-height: 1.2;
    padding: 0px 24px;
}

.team-wrapper.left{
    justify-content: flex-end;
}
.team-wrapper.right{
    justify-content: flex-start;
}
.schedule-match-info .team-wrapper .score{
    font-size: 64px;
    line-height: 1.2;
}
.team-players{
    flex-wrap: wrap;
    width: 100%;
}
.team-players.right{
    padding-left: 20px;
}
.team-players.left{
    padding-right: 20px;
    text-align: right;
}

.match-link{
    color: #A2EF56;
    font-weight: bold;
    text-decoration: underline;
}

@media only screen and (max-width: 1200px){
    .schedule-list .ant-list-item{
        flex-direction: column;
    }

    .schedule-match-info{
        margin-right: 0px;
    }

    .schedule-list .schedule-court-time{
        flex-direction: column-reverse;
    }

    .schedule-list .schedule-court{
        margin-left: 0px;
        margin-bottom: 12px;
    }
    
}

@media only screen and (max-width: 576px) {
    /*Schedule Styling*/
    .schedule-list.ant-list{
        padding: 12px 0px;
    }
    .schedule-list .ant-list-item{
        padding: 12px 6px;
        gap: 4px;
    }

    .schedule-list .schedule-date{
        font-size: 18px;
        padding-bottom: 14px;
        padding-left: 12px;
        padding-right: 12px;
    }

    .schedule-list .schedule-time{
        padding: 2px 8px;
    }

    .schedule-match-info{
        align-items: flex-start;
    }

    .schedule-match-info .team-wrapper{
        flex-direction: column;
    }
    .team-players{
        flex-wrap: wrap;
        font-size: 16px;
        line-height: 1.4;
        padding-top: 6px;
    }
    .team-players.right{
        padding-left: 0px;
        text-align: center;
    }
    .team-players.left{
        padding-right: 0px;
        text-align: center;
        order: 1;
    }

    .schedule-match-info .team-wrapper .score{
        font-size: 42px;
    }
    
    .schedule-match-info .vs-wrapper{
        font-size: 20px;
        padding: 0px 16px;
        height: 100%;
    }
    .schedule-match-info .vs-wrapper p{ 
        padding-top: 16px;      
    }
}