.snow,
.winter-is-coming {
	pointer-events: none;
}

.winter-is-coming {
	overflow: hidden;
	position: absolute;
	top: 0;
	height: 100vh;
	width: 100%;
	max-width: 100%;
}

.snow {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	-webkit-animation: falling linear infinite both;
	animation: falling linear infinite both;
	transform: translate3D(0, -100%, 0);
}
.snow--near {
	-webkit-animation-duration: 15s;
	animation-duration: 15s;
	background-image: url("https://dl6rt3mwcjzxg.cloudfront.net/assets/snow/snow-large-075d267ecbc42e3564c8ed43516dd557.png");
	background-size: contain;
}
.snow--near + .snow--alt {
	-webkit-animation-delay: 10s;
	animation-delay: 10s;
}
.snow--mid {
	-webkit-animation-duration: 25s;
	animation-duration: 25s;
	background-image: url("https://dl6rt3mwcjzxg.cloudfront.net/assets/snow/snow-medium-0b8a5e0732315b68e1f54185be7a1ad9.png");
	background-size: contain;
}
.snow--mid + .snow--alt {
	-webkit-animation-delay: 15s;
	animation-delay: 15s;
}
.snow--far {
	-webkit-animation-duration: 35s;
	animation-duration: 35s;
	background-image: url("https://dl6rt3mwcjzxg.cloudfront.net/assets/snow/snow-small-1ecd03b1fce08c24e064ff8c0a72c519.png");
	background-size: contain;
}
.snow--far + .snow--alt {
	-webkit-animation-delay: 20s;
	animation-delay: 20s;
}

@-webkit-keyframes falling {
	0% {
		transform: translate3D(-7.5%, -100%, 0);
	}
	100% {
		transform: translate3D(7.5%, 100%, 0);
	}
}

@keyframes falling {
	0% {
		transform: translate3D(-7.5%, -100%, 0);
	}
	100% {
		transform: translate3D(7.5%, 100%, 0);
	}
}