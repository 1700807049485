#countdown-wrapper {
	position: relative;
	display: inline-flex;
	justify-content: center;
	flex-wrap: wrap;
	min-height: 60px;
	max-width: 550px;
	padding: 16px 32px;
	border-radius: 12px;
	box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
	z-index: 1;
	background: url("../assets/Neon-Dot-Border.svg");
	background-size: 20px;
	background-repeat: round;
	background-color: #002261;
	overflow: hidden;
}
#countdown-wrapper::before {
	content: "";
	position: absolute;
	top: 20px;
	left: 20px;
	right: 20px;
	bottom: 20px;
	background-color: white;
	border-radius: 4px;
	z-index: -1;
}

#countdown-wrapper  .ant-statistic{
    display: inline-flex;
	flex-direction: column;
	justify-content: center;
	max-width: 100px !important;
	margin: 0px 8px;
}
#countdown-wrapper .ant-statistic-title {
	color: #fff !important;
	font-size: 20px !important;
	margin-bottom: 16px !important;
	font-weight: 600;
}
#countdown-wrapper .ant-statistic-content {
	display: flex;
	flex-direction: column;
	padding: 0px 20px;
	padding-bottom: 12px;
	min-width: 50px;
	width:100%;
}

#countdown-wrapper .ant-statistic-content-value-int {
	font-size: 70px;
	font-family: "Barlow", sans-serif;
	line-height: 1.4;
	font-weight: 600;
}
#countdown-wrapper .ant-statistic-content-suffix {
	text-transform: uppercase;
	font-family: "Barlow", sans-serif;
	font-weight: 400;
	margin-top: -8px;
}

@media only screen and (max-width: 576px) {
	#countdown-wrapper {
		padding: 12px 24px;
		background-size: 15px;
		border-radius: 6px;
	}
	#countdown-wrapper::before {
		top: 15px;
		left: 15px;
		right: 15px;
		bottom: 15px;
	}

	#countdown-wrapper  .ant-statistic{
		max-width: 50px !important;
	}
	#countdown-wrapper .ant-statistic-content {
		padding: 6px 8px;
		padding-bottom: 12px;
	}

	#countdown-wrapper .ant-statistic-content-value-int {
		font-size: 36px;
	}
	#countdown-wrapper .ant-statistic-content-suffix {
		font-size: 14px;
		margin-top: 0px;
	}
}