.gallery-body-wrapper{
	justify-content: flex-start;
	height: 100vh;
}

#gallery-container{
	justify-content: space-between;
	margin-top: 10px;
	padding-top: 20px;
}

#gallery-container > .ant-tabs {
	width: 100%;
}

masonry-wrapper > masonry-item img {
	border-radius: 4px;
	width: 100%;
}

@media only screen and (max-width: 576px) {
	#gallery-container {
		margin-top: 6px;
		padding-top: 24px;
		width: 90%;
	}

	masonry-wrapper {
		flex-direction: column !important;
	}
	masonry-wrapper > masonry-item {
		width: 100% !important;
	}
}

#gallery-container > .ant-tabs > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list > .ant-tabs-tab > .ant-tabs-tab-btn {
	color: white !important;
	font-family: "Barlow",sans-serif !important;
	font-size: 16px !important;
	margin: 0px !important;
}

#gallery-container > .ant-tabs > .ant-tabs-nav::before {
	border-bottom: 0.5px solid grey !important;
}
