.list-wrapper .ant-list, .groups-wrapper{
    margin-top: -20px;
    overflow: hidden;
}

/*Groups Styling*/
.groups-wrapper{
    margin-top: -24px;
    margin-bottom: 12px;
}
.groups-wrapper .ant-tabs-tab-btn{
    color:white;
    font-family: 'barlow',sans-serif;
    font-size: 18px !important;
}
.groups-wrapper .ant-tabs-tab-active .ant-tabs-tab-btn{
    color:#A2EF56 !important;
    font-weight: 600;
}
.groups-wrapper .ant-tabs-ink-bar{
    background-color:#A2EF56 !important;
}
.groups-wrapper  .ant-tabs-nav::before{
    border-bottom: 1px solid rgba(255,255,255,0.2);
}
.groups-wrapper .ant-tabs-content{
    margin-top: 32px;
}

.groups-wrapper .ant-tabs-content .groups-list{
    width: 100%;
    padding: 12px 20px;
}
.groups-wrapper .ant-list-header{
    color: white;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
}

.groups-wrapper .ant-list-item-meta{
    display: flex !important;
    align-items: center !important;
}
.groups-wrapper .ant-list-item-meta .ant-list-item-meta-title{
    font-size: 18px !important;
    color:white !important;
    font-weight: 400 !important;
}
.groups-wrapper .ant-list-item-meta .ant-avatar{
    width: 64px;
    height: 64px;
    border-radius: 60px;
    border: 4px solid #A2EF56;
}

@media only screen and (max-width: 576px) {
    .list-wrapper .ant-list, .groups-wrapper{
        margin-top: -8px;
        padding: 0px;
    }

    /*Groups Styling*/
    .groups-wrapper .ant-tabs-tab-btn{
        font-size: 16px !important;
    }
    .groups-wrapper .ant-list{
        padding: 12px;
    }

    .groups-wrapper .ant-tabs-content{
        margin-top: 16px;
    }
    .groups-wrapper .ant-tabs-tabpane .ant-flex{
        display: flex;
        flex-direction: column;
        gap: 32px;
    }

    .groups-wrapper .ant-list-header{
        font-size: 18px;
    }
    .groups-wrapper .ant-list-item-meta .ant-list-item-meta-title{
        font-size: 16px !important;
    }
}
