#matches-container{
    gap: 28px;
}
#matches-container .card-wrapper{
    width: 90%;
}
#matches-container .ant-card{
    border-radius: 20px;
    background-color: #232323;
    border: 1px solid #4d4d4d;
}
#matches-container .ant-card-body{
    padding: 16px !important;
}

.match-results-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 24px 0px 36px 0px;
    width: 100%;
}
.match-results-wrapper .vs-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    text-transform: uppercase;
    line-height: 1.2;
    padding: 0px 24px;
    font-weight: 600;;
    color:white
}
.match-results-wrapper .team-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.2;
    flex: 1;
}
.match-results-wrapper .team-wrapper.left{
    justify-content: flex-end;
}

.match-results-wrapper .match-score{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 60px !important;
    line-height: 1.3;
    padding: 12px 24px;
    margin: 0px !important;
    background-color: white;
}

.match-results-wrapper .team-players{
    display: flex;
    align-items: center;
    width: auto;
    background-color: #A2EF56;
    padding: 12px;
    margin: 0px;
}
.match-results-wrapper .team-players.left p{
    justify-content: flex-end;
}
.match-results-wrapper .team-players.right{
    clip-path:  polygon(0 0,100% 0,calc(100% - 32px) 100%,0 100%);
    padding-right: 32px;
}
.match-results-wrapper .team-players.left{
    clip-path: polygon(32px 0,100% 0,100% 100%,0 100%);
    padding-left: 32px;
}

.match-video-info{
    display: flex;
    flex-wrap:wrap;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    font-size: 16px;
    font-weight:400;
    color:white;
    gap: 12px;
    text-align: center;
}
.match-video-link, .match-video-date{
    display: flex;
    justify-content: center;
    align-items: center;
}
.match-video-link p, .match-video-date p{
    display: flex;
    justify-content: center;
    gap: 12px;
    margin: 0;
}
.match-video-link a{
    color:white;
    text-decoration: underline;
    font-weight: 600;
}

@media only screen and (max-width: 1200px){
    .match-results-wrapper{
        align-items: stretch;
    }

    .match-results-wrapper .team-wrapper{
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        flex: 1;
    }
    .match-results-wrapper .team-wrapper.left{
        flex-direction: column-reverse;
        justify-content: flex-end;
    }
    .match-results-wrapper .team-players{
        clip-path: none !important;
        justify-content: center;
        text-align: center;
    }
    .match-results-wrapper .team-players.right, .match-results-wrapper .team-players.left{
        padding: 12px 16px !important;
    }
    .match-results-wrapper .team-players.right{
        justify-content: flex-start !important;
    }

    .match-results-wrapper .vs-wrapper{
        position: relative;
        width: 32px;
    }

    .match-results-wrapper .vs-wrapper p{
        position: absolute;
        top: 18px;
    }

    .match-results-wrapper .match-score{
        margin-bottom: 24px !important;
    }
    
}

@media only screen and (max-width: 576px) {
    .match-results-wrapper .team-wrapper{
        flex: initial;
    }
    .match-results-wrapper .team-wrapper.left{
        flex-direction: column;
        justify-content: flex-start;
    }

    .match-results-wrapper .match-score{
        font-size: 42px !important;
        padding: 8px 16px;
    }

    .match-results-wrapper .team-players.right, .match-results-wrapper .team-players.left{
        padding: 6px 12px !important;
    }

    .match-results-wrapper .vs-wrapper p{
        position: absolute;
        top: 6px;
    }

    .match-video-info{
        flex-direction: column;
        gap: 8px;
    }
}

.match-tags {
    display: flex;
    flex-wrap: wrap;
    margin-top: 6px;
    border-top: 1px solid grey;
    padding-top: 14px;
    width: 100%;
    justify-content: center;
    row-gap: 8px;
}