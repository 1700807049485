/*Standings Styling*/
.standings-wrapper.ant-list{
    padding-bottom: 0px;
}

.standings-wrapper .ant-list-items{
    display: flex;
    justify-content: center;
    font-weight: 600;
}
.standings-wrapper .ant-list-item{
    color: white;
    font-size: 18px;
    justify-content: center;
    width: 100%;
    padding: 0px !important;
}

.standings-wrapper .podium{
    position: relative;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: flex-end;
    max-width: 150px;
    width: 33%;
}

.standings-wrapper .gold-player{
    order: 1;
}
.standings-wrapper .silver-player{
    order: 0;
}
.standings-wrapper .bronze-player{
    order: 2;
}

.podium .block{
    height: 180px;
    width: 100%;
    background-color: rgba(162, 239, 86, 0.5);
    margin-top: -100px;
}
.podium .player-info{
    display: flex;
    flex-direction: column;
    z-index: 2;
    justify-content: flex-end;
    align-items: center;
    text-align: center;
    line-height: 1.2;
}
.podium .player-info p{
    margin: 0;
    border-radius:12px;
    padding: 4px 12px;
    color: rgba(0,0,0, 0.88);
    font-weight: 600;
    line-height: 1.2;
    background-color:white;
    margin-top: -24px;
    margin-bottom: 12px;
}
.podium .player-avatar{
    width: 80px;
    height: 80px;
    border-radius: 80px;
    margin-bottom: 12px;
    border: 6px solid white;
}

.podium .player-info .name-wrapper{
    padding: 0px 6px;
}

.podium.gold-player .block{
    height: 250px;
    background-color: #A2EF56;
}
.podium.gold-player .player-avatar{
    top: 120px;
    border: 6px solid #ffaa00 !important;
}
.podium.gold-player .player-info p{
    background-color: #ffaa00;
}
.podium.gold-player .player-info .name-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1.2;
    font-size: 20px;
    font-weight: 600;
    color: black;
}

@media only screen and (max-width: 576px) {
    .standings-wrapper.ant-list{
        padding: 12px 12px 0px 12px !important;
    }

    .podium .block{
        height: 160px;
        margin-top: -80px;
    }
    .podium .player-info p{
        margin-top: -24px;
        padding: 4px 12px;
    }
    .podium .player-info .name-wrapper{
        font-size: 16px;
    }
    .podium .player-avatar{
        width: 64px;
        height: 64px;
        border-radius: 64px;
        margin-bottom: 12px;
        border: 5px solid white;
    }
    
    .podium.gold-player .block{
        height: 200px;
    }
    .podium.gold-player .player-avatar{
        top: 120px;
    }
    .podium.gold-player .player-info .name-wrapper{
        font-size: 16px;
    }
}