@font-face {
	font-family: "Barlow";
	src: local("Barlow"),
		url("../assets/fonts/Barlow-Regular.ttf") format("truetype");
	font-weight: 400;
}

@font-face {
	font-family: "Barlow";
	src: local("Barlow"),
		url("../assets/fonts/Barlow-SemiBold.ttf") format("truetype");
	font-weight: 600;
}

img {
	max-width: 700px;
}

h2 {
	font-size: 34px !important;
	font-weight: 600;
	font-family: "Barlow", sans-serif !important;
}

p,
span {
	font-family: "Barlow", sans-serif !important;
}

.body-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	min-height: 100vh;
	background-color: #191919;
}
.container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 90%;
    height: 100%;
	max-width: 1400px;
	margin-top: 60px;
	overflow-x: hidden;
}

.section {
	max-width: 1200px;
	width: 100%;
	padding: 32px 20px;
}

.button{
	border-radius: 32px;
	font-size: 20px;
	font-weight: 600;
	padding: 16px 32px;
	height: 54px !important;
	max-width: 100%;
	margin-top: 24px;
}

@media only screen and (max-width: 576px) {
	.container {
		margin-top: 60px;
		width: 100%;
	}
	.button{
		font-size: 18px;
		margin-top: 32px;
	}
	.mobile-hide{
		display: none;
	}
}