#home-container {
	text-align: center;
	width: 100%;
	margin-top: 0px;
}

.home-body-wrapper{
	justify-content: center;
	background-color: #ff4a4a;
}

.hero-section {
	padding-top: 100px;
	padding-bottom: 10px;
	background: url("../assets/Starburst.png");
	background-size: 1400px;
	background-position: center -20px;
	background-repeat: no-repeat;
}

.promo-section{
	width: 90%;
	max-width: 1100px;
	padding-bottom: 10px;
}

.players-section{
	width: 90%;
	max-width: 1100px;
}

.faq-section {
	z-index: 1;
	width: 90%;
	max-width: 1100px;
	padding-bottom: 0px;
}

.faq-section > h2 {
	color: white;
}

.faq-section > .ant-space{
	background: white;
	border-radius: 24px;
	padding: 16px;
	z-index: 1;
	box-sizing: border-box;
}
.faq-section .ant-collapse-header {
	font-size: 18px;
	font-weight: 600;
	text-align: left;
	padding-left: 0px !important;
	background-color: white;
}
.faq-section .ant-collapse-content-box {
	background-color: white;
}
.faq-section .ant-collapse-content-box > p {
	font-size: 17px;
	line-height: 1.7;
	text-align: left;
	margin: 8px 12px 8px 12px;
	font-weight: 400;
}

.ant-collapse-header .ant-collapse-expand-icon{
	margin-left: 0px !important;
}

/*Responsive*/

@media only screen and (max-width: 576px) {
	.hero-section {
		padding-top: 80px;
		background-position: center 80%;
		background-size: 1200px;
	}

	.faq-section {
		width: 90%;
		padding: 24px 16px;
		padding-bottom: 0px;
	}
	.faq-section > h2 {
		font-size: 24px !important;
	}
	.faq-section .ant-collapse-header {
		font-size: 16px;
	}
	.faq-section .ant-collapse-content-box > p {
		font-size: 15px;
	}
}
