#navigation-bar > div {
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 600;
}
#navigation-bar #nav-logo{
	display: flex;
	justify-content: center;
	align-items: center;
	height: 60px !important;
	border-radius: 32px 0px 0px 32px;
	background-color: rgba(0, 0, 0, 0.7);
}

#navigation-bar #nav-logo > img{
	width: 48px;
	height: 48px;
	padding: 0px 12px;
}

#navigation-bar .ant-tabs-tab,
#navigation-bar .ant-tabs .ant-tabs-tab.ant-tabs-tab-active,
.ant-tabs-tab-btn a {
	color: white !important;
	font-family: "Barlow",sans-serif !important;
	font-size: 16px !important;
	margin: 0px !important;
	padding: 6px 8px !important;
}

.ant-tabs .ant-tabs-ink-bar {
	background: white !important;
	height: 3.5px;
}

#navigation-bar .ant-tabs-nav{
	margin: 0px !important;
	height: 60px !important;
	border-radius: 0px 32px 32px 0px;
	background-color: rgba(0, 0, 0, 0.7);
	padding-right: 24px;
}

#navigation-bar .ant-tabs-nav::before {
	border: none;
}

@media only screen and (max-width: 576px) {
	#navigation-bar #nav-logo{
		height: 46px !important;
	}
	#navigation-bar #nav-logo > img{
		width: 32px;
		height: 32px;
		padding: 0px 6px;
	}
	#navigation-bar .ant-tabs-tab,
	#navigation-bar .ant-tabs .ant-tabs-tab.ant-tabs-tab-active,
	.ant-tabs-tab-btn {
        padding: 0px !important;
	}
	.ant-tabs .ant-tabs-ink-bar {
		height: 2.5px;
	}
	#navigation-bar .ant-tabs-nav{
		height: 46px !important;
		padding-right: 12px;
	}
    #navigation-bar .ant-tabs-nav a{
        font-size: 14px !important;
        padding: 6px 8px !important
	}

}