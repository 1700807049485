.tournament-details-wrapper{
    width: 90%;
}
.tournament-details-wrapper .list-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 54px;
}

.list-wrapper .card-header{
    font-size: 34px !important;
    color:white;
    font-weight: 600;
    margin: 0px;
    text-align: center;
}
.list-wrapper .ant-list{
    padding: 12px;
}

@media only screen and (max-width: 576px) {
    .tournament-details-wrapper .list-wrapper{
        gap: 32px;
    }
    
    .list-wrapper .card-header{
        font-size: 24px !important;
    }
    .list-wrapper .ant-list, .groups-wrapper{
        margin-top: -8px;
        padding: 0px;
    }

    .players-grid .ant-list-items{
        grid-template-columns: repeat(1, 1fr);
    }
}