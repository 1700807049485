.match-filter-wrapper{
    display: flex;
    justify-content: center;
    width: 90%;
    gap: 16px;
    flex-wrap: wrap;
}
.date-filter-wrapper > .ant-picker{
    width: 100%;
}
.player-filter-wrapper{
    display: flex;
    width: 100%;
    gap: 16px;
}
.tag-filter-wrapper > .ant-checkbox-group{
    display: flex;
    width: 100%;
    row-gap: 6px;
    column-gap: 12px;
}
.tag-filter-wrapper > .ant-checkbox-group > label > span{
    color: white;
}

.match-filter-wrapper .ant-picker, .match-filter-wrapper .ant-select-selector{
    background-color: #232323 !important;
    border: 1px solid #4d4d4d !important;
    border-radius: 8px !important;
}
.match-filter-wrapper .ant-picker svg{
    color: white !important;
}
.match-filter-wrapper .ant-picker{
    height: 54px;
}
.match-filter-wrapper .ant-picker-active-bar{
    background-color: #A2EF56 !important;
}

.ant-picker-input >input{
    color: white !important;
}
.match-filter-wrapper .ant-picker input::placeholder, .match-filter-wrapper .ant-select-arrow{
    color: white;
    font-weight: 600;
    font-family: 'Barlow', sans-serif;
}

.match-filter-wrapper .ant-select-selector{
    padding: 12px;
}
.match-filter-wrapper .ant-select-selection-item{
    background-color: rgba(255,255,255,0.1) !important;
}
.match-filter-wrapper .ant-select-selection-item-content, .match-filter-wrapper .ant-select-selection-item-remove{
    color: white !important;
}

@media only screen and (max-width: 576px) {
    .match-filter-wrapper{
        flex-direction: column;
    }
    .player-filter-wrapper{
        flex-wrap: wrap;
        justify-content: center;
        gap: 4px;
    }
    .match-filter-wrapper .ant-select{
        width: 100% !important;
    }
}

.dateRangePicker .ant-picker-panel:nth-child(2){
    display: none;
  }

.dateRangePicker .ant-picker-panel:nth-child(1) button{
    visibility: visible !important;
}