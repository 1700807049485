/*Players Styling*/
.players-section .card-header{
    font-size: 34px !important;
    color: white;
    font-weight: 600;
    margin: 0px;
    margin-bottom: 32px;;
    text-align: center;
}

.players-grid .ant-list-items{
    display: grid !important;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.5px;
    background-color: rgb(224, 224, 224);
    border-radius: 24px;
    overflow: hidden;
}
.players-grid .ant-list-item{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 46px 12px 18px 12px;
    font-size: 18px;
    background-color: white;
}

.players-grid .player-name{
    margin: 0px;
    display: flex;
    align-items: center;
    margin-left: 16px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.88);
}
.players-grid .player-number{
    position: absolute;
    font-size: 24px;
    font-weight: 600;
    top: -12px;
    left: 16px;
}
.players-grid .player-avatar{
    width: 64px;
    height: 64px;
    border-radius: 60px;
    border: 5px solid #ff4a4a;
}

.player-description .ant-descriptions-item-label {
    width: 40px;
    justify-content: center !important;
    align-items: center !important;
}
.player-description .ant-descriptions-item-container  span{
    font-size: 16px;
    text-align: left;
}
.player-description .ant-descriptions-item-container  span > svg{
    color: rgba(0, 0, 0, 0.7);
    font-size: 20px;
}
.player-description .ant-descriptions-item{
    padding-bottom: 0px !important;
    padding: 12px 0px !important;
}
.player-description .ant-descriptions-row:not(:last-child) .ant-descriptions-item{
    border-bottom: 1px solid rgba(0,0,0,0.2);
}
.player-description .ant-descriptions-item .ant-descriptions-item-content > span{
    display: flex;
    align-items: center;
    gap: 3px;
}
.player-description .ant-descriptions-item .ant-descriptions-item-content > span > svg{
    font-size: 14px;
    margin-bottom: 10px;
}
.player-description .ant-descriptions-item .ant-descriptions-item-content > a > .player-statistics-link {
    font-size: 12px;
    padding: 0px 0px 5px 5px;
}

@media only screen and (max-width: 1200px){
    .players-grid .ant-list-items{
        grid-template-columns: repeat(2, 1fr);
    }
}

@media only screen and (max-width: 576px) {
    .players-grid .ant-list-items{
        grid-template-columns: repeat(1, 1fr);
    }
    .players-section .card-header{
        font-size: 24px !important;
    }
    .players-section{
        margin-bottom: 0px;
    }
}